//Variables to replace
const poolId = 'us-west-2_gnqAr49w8'
const clientId = '6av1vgqg7iavb40f6lqvss0c2n'
const api = 'https://fowqmsibvf.execute-api.us-west-2.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config